import React, { useEffect, useState } from 'react'
import { Container, Col, Row, Nav, Tab, Card, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';

import * as MovieAction from '../../../actions/MovieAction'
import { LOCALITY_IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { ROUTES } from '../../../constant/routes';
import rent_icon from '../../../assets/regal_images/Rent (1).png'
import design from '../../../assets/regal_images/divider.png'
import { useHistory } from 'react-router-dom';

export default function Category({ category }) {
    const history = useHistory()
    return (
        <>
            {
                category?.data?.length ?
                    <div className='main-content'>
                        <section id="iq-favorites">
                            <Container fluid>
                                <Row>
                                    <Col sm="12" className="overflow-hidden">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img src={design} className='mr-2' alt='design' style={{ width: "10%" }} />
                                                <h6 className={"main-title "} style={{ textTransform: "uppercase" }}>{category?.category_name || "Loading"}</h6>

                                                <img src={design} className="ml-2" alt='design' style={{ width: "10%" }} />

                                            </div>

                                        </div>
                                        <div id="favorites-contens">
                                            <Swiper
                                                navigation={{
                                                    prevEl: '#prev',
                                                    nextEl: '#next'
                                                }}
                                                breakpoints={{
                                                    320: { slidesPerView: 1 },
                                                    550: { slidesPerView: 2 },
                                                    991: { slidesPerView: 3 },
                                                    1400: { slidesPerView: 4 }
                                                }}
                                                loop={false}
                                                slidesPerView={6}
                                                spaceBetween={0}
                                                as="ul"
                                                id={'slider-' + category?.category_name}
                                                onTransitionEnd={(slider) => {
                                                    var view_all = document.getElementById("view-all-" + category?.category_name)
                                                    if ((slider?.virtualSize + slider?.translate) == slider?.size) {
                                                        view_all.style.display = "block"
                                                    }
                                                    else {
                                                        view_all.style.display = "none"

                                                    }
                                                }}
                                                className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">
                                                {
                                                    category?.data?.length ?

                                                        category?.data?.map((data, index) => (
                                                            <SwiperSlide style={{ cursor: "pointer" }} as="li" key={index}>
                                                                <div onClick={() => history.push(ROUTES.moviedetails + '/' + data?.slug + `/image-thumb/${LOCALITY_IMAGE_ENDPOINT}${data?.video_poster}`)} className="image-container">
                                                                    <img src={`${LOCALITY_IMAGE_ENDPOINT}/${data?.video_poster}`} className={`poster-img `} alt={data?.video_name} />
                                                                    <div className="image-overlay">{data?.video_name}</div>


                                                                    {/* <Card className='hover-class'>
                                                                        <Card.Img variant="top" src={`${LOCALITY_IMAGE_ENDPOINT}/${data?.video_poster}`} />
                                                                        <Card.Body style={{ marginTop: "-1rem" }}>
                                                                            <span style={{ color: "#ffffff", fontSize: "13px" }}>{data?.video_name}
                                                                            </span><br />
                                                                            <div style={{ marginTop: "-0.3rem" }}>
                                                                                <span style={{ color: "#ffffff", fontSize: "10px" }}>Sub-Category: {data?.video_subcategory}
                                                                                </span>
                                                                                <p style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: data?.video_description?.length <= 60 ? data?.video_description : data?.video_description?.substring(0, 60) + "..." }}></p>
                                                                                <div style={{ display: "flex", background: "#ffffff", color: "#000000", fontWeight: "bolder", borderRadius: "0.5rem", }}>
                                                                                    <div style={{ fontSize: "13px", margin: "0 auto", padding: "5px" }}><i className="fa fa-info" aria-hidden="true"></i>  Details</div>
                                                                                </div>
                                                                            </div>

                                                                        </Card.Body>
                                                                    </Card> */}
                                                                </div>
                                                            </SwiperSlide>


                                                        ))

                                                        :
                                                        <></>
                                                }

                                            </Swiper>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </div>
                    :
                    <></>
            }

        </>
    )
}
